import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import HeroWithImage from "../../components/heroWithImage";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import PficStatements from "../../containers/investors/PficStatements";

import heroDesktop from "../../assets/heroDesktop/Agm.png";
import heroMobile from "../../assets/heroMobile/Agm.png";

const PficAnnualInformation = ({ data }) => {
  const intl = useIntl();
  return (
    <Layout inverted>
      <Seo title="PFIC ANNUAL INFORMATION" />
      <HeroWithImage
        title={intl.locale === "fr" ? "RENSEIGNEMENTS ANNUELS PFIC" : "PFIC ANNUAL INFORMATION"}
        heroMobile={heroMobile}
        heroDesktop={heroDesktop}
      />
      <PficStatements data={data.documents.nodes} />
    </Layout>
  );
};

export const query = graphql`
  query($locale: String) {
    documents: allContentfulPficAnnualInformation(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        link
      }
    }
  }
`;

export default PficAnnualInformation;
